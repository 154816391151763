import React from 'react'
import { Grid, Stack } from '@mui/material'
import Typography from "@mui/material/Typography"
import Divider from '@mui/material/Divider'

export const HeaderTitle = ({ title }) => {
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 2, marginTop: 40, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
    >

      <Grid
        item xs={12}
        style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 3, color: '#2C3E5D', background: '#FFFFFF' }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          alignItems="center"
        >
          <img src='/images/Lipigas_color_horizontal.svg' style={{ width: '11%' }}></img>
          <Typography variant="h6" component="h2" mb={2}>
            {title}
          </Typography>
        </Stack>
      </Grid>

    </Grid>
  )
}
