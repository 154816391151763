const apiUrl = process.env.REACT_APP_API_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

export const saveAttachment = async (body) => {

  const formData = new FormData();
  formData.append("idRequerimiento", body.idRequerimiento);
  formData.append("idUsuario", body.idUsuario);
  formData.append("nombreArchivo", body.nombreArchivo);
  formData.append("observacion", body.observacion);
  formData.append("archivo", body.archivo);
  formData.append("idTipoRed", body.idTipoRed);

  const url = `${apiUrl}/AdjuntosLlae`;

  try {
    const resp = await fetch(url, {
      method: 'post',
      headers: { 'Authorization': sessionStorage.getItem('token') },
      body: formData,
      redirect: 'follow'
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const getAttachments = async (idRequerimiento) => {
  const url = `${apiUrl}/AdjuntosLlae/?idRequerimiento=${idRequerimiento}`;

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const removeAttachment = async ({idAdjunto, idUsuario}) => {
  const url = `${apiUrl}/AdjuntosLlae/anular?idAdjunto=${idAdjunto}&idUsuario=${idUsuario}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const generateAttachment = async (idRequerimiento, idUsuario) => {
  const url = `${apiUrl}/AdjuntosLlae/generar?idRequerimiento=${idRequerimiento}&idUsuario=${idUsuario}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: { 'Authorization': sessionStorage.getItem('token') },
      //headers: myHeaders,
      redirect: 'follow',
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const updateObsAttachment = async (pdf, observacion) => {
  const url = `${apiUrl}/AdjuntosLlae/actualizar_observacion?pdf=${pdf}&observacion=${observacion}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const openAttachment = async (idAdjunto) => {
  const url = `${apiUrl}/AdjuntosLlae/ver?idAdjunto=${idAdjunto}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener el adjunto`
    };
  }

}