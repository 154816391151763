const apiUrl = process.env.REACT_APP_API_URL;
const apiNodeUrl = process.env.REACT_APP_NODE_AUTH_API_URL;
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')
  
export const sendForm = async (body) => {
  const url = `${apiUrl}/RequerimientoLlae`;

  try {
    const resp = await fetch(url, {
      method: 'post',
      headers: myHeaders,
      body
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const getAvailability = async (fechaDesde, fechaHasta) => {
  const url = `${apiUrl}/RequerimientoLlae?${new URLSearchParams({ fechaDesde, fechaHasta })}`;

  try {
    const resp = await fetch( url, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const addRequest = async (parseObject) => {
  const url = `${apiUrl}/RequerimientoLlae`;
  
  try {
    const resp = await fetch( url, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(parseObject),
      redirect: 'follow'
    })

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para agregar el requerimiento`
    };
  }
}

export const update = async ({ parseObject }) => {
  const url = `${apiUrl}/RequerimientoLlae`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch( url, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(parseObject),
      redirect: 'follow'
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para agregar el requerimiento`
    };
  }
}

export const accept = async ({ idUsuario, idRequerimiento }) => {
  const url = `${apiUrl}/RequerimientoLlae/recepcionar?${new URLSearchParams({ idUsuario, idRequerimiento })}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));
  
  try {
    const resp = await fetch( url, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      
      return {
        data,
        status: 'OK',
        message: "Atendido en 150mts"
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para agregar el requerimiento`
    };
  }
}

export const anular = async ({ idUsuario, idRequerimiento }) => {
  const url = `${apiUrl}/RequerimientoLlae/anular?${new URLSearchParams({ idUsuario, idRequerimiento })}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch( url, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para agregar el requerimiento`
    };
  }
}

export const atender = async ({ idUsuario, idRequerimiento }) => {
  const url = `${apiUrl}/RequerimientoLlae/atender?${new URLSearchParams({ idUsuario, idRequerimiento })}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch( url, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para agregar el requerimiento`
    };
  }
}

export const getAvailabilityById = async id => {
  const url = `${apiUrl}/RequerimientoLlae?idRequerimiento=${id}`;

  myHeaders.delete("Authorization");
  myHeaders.append("Authorization", sessionStorage.getItem('token'));

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders,
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const getUser = async (key) => {
  const url = `${apiNodeUrl}/auth`;

  myHeaders.delete("Authorization");

  var raw = JSON.stringify({
    "key": `${key}`
  });

  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    })

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      myHeaders.append("Authorization", sessionStorage.getItem('token'));
      return {
        data,
        status: 'OK'
      };
    } else if (resp.status === 401 || resp.status === 403) {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: 'Usuario no autorizado'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener el usuario`
    };
  }

}

export const getFirmante = async (idUsuario) => {
  const url = `${apiUrl}/AdjuntosLlae/firmante?idUsuario=${idUsuario}`;

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders,
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();
      
      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para determinar firmante`
    };
  }

}