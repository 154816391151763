import React, { useState } from "react"
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'

export const TitleFile = ({ text, setFilename }) => {
  
  const handelDelete = () => setFilename('')

  return (
    <Chip label= { text } onDelete={ handelDelete } variant="outlined" style={{ backgroundColor: 'white' }}/>
  )
}
