import * as Yup from 'yup';
import { validateRut } from "@fdograph/rut-utilities";


export const editSchema = Yup.object().shape({
  empresaMandante: Yup
    .string()
    .label('Empresa Mandante')
    .max(50)
    .required('Este campo es requerido')
    .min(3),
  rubro: Yup
    .string()
    .label('Rubro empresa mandante')
    .max(50)
    .required('Este campo es requerido'),
  empresaSolicitante: Yup
    .string()
    .label('Empresa solicitante')
    .max(50)
    .required('Este campo es requerido'),
  contacto: Yup
    .string()
    .label('Nombre del contacto')
    .max(50)
    .required('Este campo es requerido'),
  correo: Yup
    .string()
    .label('El email')
    .max(50)
    .required('Este campo es requerido')
    .email(),
  telefono: Yup
    .string()
    .label('El teléfono')
    .required('Este campo es requerido')
    .matches(/^\d{9}$/, '${path} debe contener 9 dígitos'),
  region: Yup
    .object()
    .nullable()
    .label('La región')
    .required('Este campo es requerido'),
  comuna: Yup
    .object()
    .nullable()
    .label('La comuna')
    .required('Este campo es requerido'),
  calle: Yup
    .object()
    .nullable()
    .label('La dirección')
    .required('Este campo es requerido'),
  numero: Yup
    .string()
    .label('El número de la dirección')
    .max(8)
    .required('Este campo es requerido'),
  rut: Yup
    .string()
    .required('Este campo es requerido')
    .matches(/^(\d{6,8}-([0-9]|K|k))$/, '${path} debe tener formato ######-X')
    .test('is-rut', 'Rut no es válido', (value) => validateRut(value)),
  tipoTrabajo: Yup
    .string()
    .label('El tipo de trabajo que realiza la empresa')
    .max(250)
    .required('Este campo es requerido'),
});

export const sendSchema = Yup.object().shape({
  correo: Yup
    .string()
    .label('El email')
    .required()
    .email(),
  distanciaInstalacion: Yup
    .string()
    .label('La distancia de instalación')
    .required(),
  upload: Yup
    .mixed()
    .label('El archivo')
    .test('required', '${path} es requerido', (file) => file && file.length > 0)
    .test('fileSize', '${path} no puede ser mayor que 2MB', (file) => file && file.length === 1 && file[0].size <= 2000000)
});

export const attachmentSchema = Yup.object().shape({
  observacion: Yup
    .string()
    .label('La Observación')
    .max(100)
    .required('Este campo es requerido'),
  tipored: Yup
    .object()
    .nullable()
    .label('El tipo de documento')
    .required('Este campo es requerido'),

  // upload: Yup
  //   .mixed()
  //   .label('El archivo')
  //   .test('required', '${path} es requerido', (file) => file && file.length > 0)
  //   .test('fileSize', '${path} no puede ser mayor que 2MB', (file) => file && file.length === 1 && file[0].size <= 2000000)
});


