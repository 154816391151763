import React, { useEffect, useState } from "react"
import { editSchema } from '../../utils/validations'
import Swal from 'sweetalert2'
import '../../App.css'
import {
  Button,
  FormControl,
  Grid,
  InputLabel, Stack,
  TextField,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { getComunas, getRegions } from "../../api/comunes/apiCalls"
import { addRequest } from "../../api/availability/apiCalls"
import { useHistory } from "react-router-dom"
import { isEmpty } from 'lodash'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { yupResolver } from "@hookform/resolvers/yup"
import { getStreets } from "../../api/streets/apiCalls"
import { HeaderTitle } from '../common/HeaderTitle'
import Box from '@mui/material/Box'

export const FormTechnicalFeasibility = () => {
  const history = useHistory()
  const [reload, setReload] = useState('')
  const [communes, setCommunes] = useState([])
  const [commune, setCommune] = useState(null)
  const [streets, setStreets] = useState([])
  const [street, setStreet] = useState(null)
  const [newStreet, setNewStreet] = useState(null)
  const [regions, setRegions] = useState([])
  const [region, setRegion] = useState(null)
  const { register, handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(editSchema) })

  useEffect(() => {
    getRegions().then(({ data }) => {
      setRegions(data)
    })
  }, [reload])

  const handleChangeSelect = (name, obj) => {
    if (name === 'region') {
      setCommune(null)
      setStreet(null)
      setRegion(obj)
      getComunas(obj.id).then(({ data }) => {
        setCommunes(data)
      })
    }
    if (name === 'comuna') {
      setStreet(null)
      setCommune(obj)
    }
    if (name === 'calle') {
      setStreet(obj)
    }
  };

  const onSubmit = (datos) => {
    const parseObject = {
      empresaMandante: datos.empresaMandante,
      rubro: datos.rubro,
      empresaSolicitante: datos.empresaSolicitante,
      contacto: datos.contacto,
      correo: datos.correo,
      telefono: datos.telefono,
      idRegion: datos.region.id,
      idComuna: datos.comuna.id,
      calle: datos.calle.calle,
      numero: datos.numero,
      rut: datos.rut,
      idUsuario: 1,
      tipoTrabajo: datos.tipoTrabajo
    }
    addRequest(parseObject)
      .then(({ data }) => {
        if (data.cod_error === 9000) {
          Swal.fire({
            title: `<strong><u>${data.mensaje_usr}</u></strong>`,
            icon: data.cod_error === 9000 ? 'success' : 'error',
            html: data.cod_error === 9000 ?
              `Estimado <b>${datos.empresaMandante},</b></br>` +
              `<p>Revisaremos tu requerimiento y te confirmaremos en un plazo no superior a 120 minutos.</p></br>` +
              `<b style='font-size: small'>En caso de ser necesaria consultas asociadas al proceso puede llamarnos a nuestro Centro de Operaciones de Emergencia al 600 600 9200 opción 3 correspondiente al servicio Llame Antes de excavar.<b>`
              : ''
            ,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#003087',
            cancelButtonColor: '#FF624E',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        } else {
          Swal.fire({
            title: `<strong><u>${data.mensaje_usr}</u></strong>`,
            icon: data.cod_error === 9099 ? 'error' : 'success',
            html: data.cod_error === 9099 ?
              `Estimado <b>${datos.empresaMandante},</b></br>` +
              `<p>Error en el envío de solicitud. Intente nuevamente.</p></br>` +
              `<b style='font-size: small'>En caso de ser necesaria consultas asociadas al proceso puede llamarnos a nuestro Centro de Operaciones de Emergencia al 600 600 9200 opción 3 correspondiente al servicio Llame Antes de excavar.<b>`
              : ''
            ,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#003087',
            cancelButtonColor: '#FF624E',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
            if (result.isConfirmed) {
              //window.location.reload()
            }
          })
        }
      })
  }

  return (
    <form style={styles.fontMain}>
      <Box sx={{ width: '100%', marginLeft: 1 }} >
        <HeaderTitle title='Formulario de Ingreso de Solicitudes' />
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 4, sm: 2, md: 3 }}
          style={{ borderBottom: '1px solid transparent', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid #B3B9C4', borderRadius: 10, padding: 2, marginTop: 40, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
        >
          <Grid item xs={12}
            style={{ borderBottom: '1px solid transparent', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid #B3B9C4', borderRadius: 10, padding: 10, background: '#FFFFFF' }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  {...register('empresaMandante')}
                  error={!!errors.empresaMandante}
                  helperText={errors.empresaMandante?.message}
                  label={'Empresa Mandante'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('rubro')}
                  error={!!errors.rubro}
                  helperText={errors.rubro?.message}
                  label={'Rubro Empresa Mandante'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('empresaSolicitante')}
                  error={!!errors.empresaSolicitante}
                  helperText={errors.empresaSolicitante?.message}
                  label={'Empresa Solicitante'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('contacto')}
                  error={!!errors.contacto}
                  helperText={errors.contacto?.message}
                  label={'Nombre del contacto'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel htmlFor="rut" style={{ display: 'none' }}>RUT</InputLabel>
                <TextField
                  {...register('rut')}
                  label={'Rut'}
                  placeholder={'Rut sin puntos y con guión'}
                  error={!!errors.rut}
                  helperText={errors.rut?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register('correo')}
                  error={!!errors.correo}
                  helperText={errors.correo?.message}
                  label={'E-Mail'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register('telefono')}
                  error={!!errors.telefono}
                  placeholder={'Número de largo 9'}
                  helperText={errors.telefono?.message}
                  label="Teléfono"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="region"
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      options={regions}
                      getOptionLabel={(option) => option.id ? `${option.id} - ${option.nombre}` : ''}
                      onChange={(_, value) => {
                        if (!isEmpty(value)) {
                          handleChangeSelect("region", value)
                        }
                        onChange(value)
                      }}
                      onInputChange={() => onChange(region)}
                      renderInput={params => <TextField
                        {...params}
                        error={!!errors.region}
                        inputProps={{ ...params.inputProps }}
                        helperText={errors.region?.message} label="Seleccione Región"
                      />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="comuna"
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      value={commune}
                      options={communes}
                      getOptionLabel={(option) => `${option.id} - ${option.nombre}`}
                      onChange={(_, value) => {
                        if (!isEmpty(value)) {
                          handleChangeSelect('comuna', value)
                        }
                        onChange(value)
                      }}
                      onInputChange={() => onChange(commune)}
                      renderInput={(params) => <TextField
                        {...params}
                        error={!!errors.comuna}
                        inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                        helperText={errors.comuna?.message} label="Seleccione Comuna"
                      />}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="calle"
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      value={street}
                      options={streets}
                      filterOptions={(x) => x}
                      onInputChange={(_, value) => {
                        if (commune && commune.id && value) {
                          getStreets({
                            filter: value,
                            comuna: commune.id,
                            cantidad: 20
                          }).then((response) => {
                            setStreets(response.data)
                          })

                          if (!isEmpty(value)) {
                            const newStreetObj = {
                              callId: 0,
                              calle: value,
                              calleBuscada: null
                            };
                            handleChangeSelect("calle", newStreetObj)
                            onChange(newStreetObj)
                          }
                        }
                      }}
                      getOptionLabel={(option) => `${option.calle}`}
                      renderInput={(params) => <TextField
                        {...params}
                        error={!!errors.calle}
                        helperText={errors.calle?.message} label="Seleccione Calle"
                      />}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register('numero')}
                  error={!!errors.numero}
                  helperText={errors.numero?.message}
                  label="Número de dirección"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('tipoTrabajo')}
                  error={!!errors.tipoTrabajo}
                  label="Tipo de Trabajo"
                  multiline
                  fullWidth
                  maxRows={2}
                  inputProps={{ maxLength: 250 }}
                />
              </Grid>
            </Grid></Grid> </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 4, sm: 2, md: 3 }}
          style={{ borderBottom: '1px solid #B3B9C4', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid transparent', borderRadius: 10, padding: 2, marginTop: -1, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
        >
          <Grid item xs={12}
            style={{ borderBottom: '1px solid #B3B9C4', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid transparent', borderRadius: 10, padding: 10, background: '#FFFFFF' }}
          >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <FormControl>
                      <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Enviar</Button>
                    </FormControl>
                  </Stack>
                </Grid>

            </Grid></Grid></Grid>

      </Box>
    </form>
  )
}



const styles = {
  fontMain: {
    fontFamily: 'Poppins, sans-serif',
  },
  fontTitle: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '1.286em'
  }
}