import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material'

export const Spinner = () => {
  return (
      <Box sx={{ width: '100%', marginLeft: 1, textAlign: 'center' }} >
            <CircularProgress color="success" />
              Subiendo Archivo ...
            
      </Box>
  )
}

