import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { TableGrid } from './TableGrid'
import Swal from 'sweetalert2'
import { HeaderTitle } from '../common/HeaderTitle'
import Box from '@mui/material/Box'
import { columnsDefinitions } from './columnsDefinitions'
import { getAvailability, accept, anular, atender, getUser, getFirmante, checkToken } from '../../api/availability/apiCalls'
import {
  Button,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import moment from 'moment'
import { isEmpty } from 'lodash'

export const AvailabilityScreen = () => {

  const location = useLocation()
  const history = useHistory();
  const [selectedRequirements, setSelectedRequirements] = location?.state?.keepObject ? useState([location.state.keepObject]) : useState([]);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState('');
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isAcceptDeleteDisabled, setIsAcceptDeleteDisabled] = useState(!location?.state?.keepObject?.id);
  const [selectedBulk, setSelectedBulk] = useState('');
  const [selectedEdit, setSelectedEdit] = location?.state?.keepObject ? useState(location.state.keepObject) : useState([null]) //useState(null);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = location?.state?.keepObject?.id ? useState([location.state.keepObject.id]) : useState([])

  const [fechaDesde, setFechaDesde] = useState(isEmpty(sessionStorage.getItem('fechaDesde')) ? moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD") : sessionStorage.getItem('fechaDesde'))
  const [fechaHasta, setFechaHasta] = useState(isEmpty(sessionStorage.getItem('fechaHasta')) ? moment(new Date()).format("YYYY-MM-DD") : sessionStorage.getItem('fechaHasta'))

  useEffect(() => {
    let isMounted = true
    if (loadUser()) {
      loadData();
    }

    return () => {
      isMounted = false
    }
  }, [reload])

  const loadData = () => {
    return getAvailability(fechaDesde, fechaHasta)
      .then((result) => {
        setData(result.data.requerimientos);
        setLoading(false);
      })
  };

  const loadUser = () => {
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('IKEYLPG')) {
      return getUser(searchParams.get('IKEYLPG'))
        .then((result) => {
          if (result.status == 'NOK') {
            history.push({
              pathname: '/no_conectado',
              state: { mensaje: result.message }
            })
          } else {
            sessionStorage.setItem('usuarioConectado', result.data.usuario);
            sessionStorage.setItem('token', result.data.token);
            return getFirmante(sessionStorage.getItem('usuarioConectado'))
              .then((result) => {
                sessionStorage.setItem('isFirmante', result.data.firma);
                setLoading(false);
              })
          }
          window.location.reload();
        })
    }
    return true;
  };

  const usuarioConectado = sessionStorage.getItem('usuarioConectado');

  const edit = () => {
    history.push({
      pathname: '/editar',
      state: { defaultFormValues: selectedEdit }
    })
  }

  const attachments = () => {
    history.push({
      pathname: '/adjuntar',
      state: { defaultFormValues: selectedEdit }
    })
  }

  const handleRecepcionar = () => {
    Swal.fire({
      title: 'Estas seguro que quieres recepcionar la solicitud?',
      text: "Esta acción no se podrá revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36B37E',
      cancelButtonColor: '#FF624E',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Recepcionalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        accept({ idUsuario: usuarioConectado, idRequerimiento: selectedEdit.idRequerimiento })
          .then(({ data }) => {
            Swal.fire({
              position: 'center',
              icon: data.cod_error === 9000 ? 'success' : 'error',
              title: data.cod_error === 9000 ? `Su solictud se recepcionó correctamente.\n\n` + data.mensaje_usr : data.mensaje_usr,
              showConfirmButton: true,
              confirmButtonColor: '#36B37E',
              confirmButtonText: 'Aceptar'
            })
            setReload(new Date())
          })
      }
    })
  }

  const handleEliminar = () => {
    Swal.fire({
      title: 'Estas seguro que quieres anular la solicitud?',
      text: "Esta acción no se podrá revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36B37E',
      cancelButtonColor: '#FF624E',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Anular!'
    }).then((result) => {
      if (result.isConfirmed) {
        anular({ idUsuario: usuarioConectado, idRequerimiento: selectedEdit.idRequerimiento })
          .then(({ data }) => {
            Swal.fire({
              position: 'center',
              icon: data.cod_error === 9000 ? 'success' : 'error',
              title: data.cod_error === 9000 ? `Su solictud se eliminó correctamente.` : data.mensaje_usr,
              showConfirmButton: true,
              confirmButtonColor: '#36B37E',
              confirmButtonText: 'Aceptar'
            })
            setReload(new Date())
          })
      }
    })
  }

  const handleEnviar = () => {
    Swal.fire({
      title: 'Estas seguro que quieres enviar la solicitud?',
      text: "Esta acción no se podrá revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36B37E',
      cancelButtonColor: '#FF624E',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Envíalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        atender({ idUsuario: usuarioConectado, idRequerimiento: selectedEdit.idRequerimiento })
          .then(({ data }) => {
            Swal.fire({
              position: 'center',
              icon: data.cod_error === 9000 ? 'success' : 'error',
              title: data.cod_error === 9000 ? `Su solictud se atendió correctamente.` : data.mensaje_usr,
              showConfirmButton: true,
              confirmButtonColor: '#36B37E',
              confirmButtonText: 'Aceptar'
            })
            setReload(new Date())
          })
      }
    })
  }

  const refreshData = () => {
    window.location.reload(false);
  }

  return (
    <div>
      <Box sx={{ width: '100%', marginLeft: 1 }} >
        <HeaderTitle title='Administrar Requerimiento LLAE' />
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 4, sm: 2, md: 3 }}
          style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 2, marginTop: 40, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
        >
          <Grid
            item xs={12}
            style={{
              border: '1px solid #B3B9C4', borderRadius: 10, padding: 10,
              backgroundColor: '#FFFFFF'
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <Button
                variant="contained"
                disabled={isAcceptDeleteDisabled}
                onClick={handleRecepcionar}
              >
                Recepcionar
              </Button>
              <Button
                variant="contained"
                disabled={isEditDisabled}
                onClick={() => edit()}
              >
                Editar
              </Button>
              <Button
                variant="contained"
                disabled={isEditDisabled}
                onClick={() => attachments()}
              >
                Adjuntar
              </Button>
              <Button
                variant="contained"
                disabled={isEditDisabled}
                onClick={handleEnviar}
              >
                Enviar
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={isAcceptDeleteDisabled}
                onClick={handleEliminar}
              >
                Anular
              </Button>

              <Button
                variant="contained"
                onClick={refreshData}
              >
                ↻
              </Button>
              <TextField
                style={{ marginLeft: '100px' }}
                id="fechaDesde"
                label="Fecha Desde"
                type="date"
                defaultValue={fechaDesde}
                size='small'

                onChange={(event, newValue) => {
                  if (!isEmpty(event.target.value)) {
                    sessionStorage.setItem('fechaDesde', moment(event.target.value).format("YYYY-MM-DD"))
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="fechaHasta"
                label="Fecha Hasta"
                type="date"
                defaultValue={fechaHasta}
                size='small'

                onChange={(event, newValue) => {
                  if (!isEmpty(event.target.value)) {
                    sessionStorage.setItem('fechaHasta', moment(event.target.value).format("YYYY-MM-DD"))
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                onClick={refreshData}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}
            style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 10, marginTop: 2, background: '#FFFFFF' }}
          >
            <TableGrid
              data={data}
              onSelectionModelChange={(selection) => {
                setSelectedRequirements(data.filter(x => x.id === selection[0]));

                if (selection.length > 1) {
                  const selectionSet = new Set(selectionModel);
                  const result = selection.filter((s) => !selectionSet.has(s));
                  setSelectionModel(result);
                  setSelectedEdit(data.find(x => x.id === result[0]));
                } else if (selection.length === 0) {
                  setIsEditDisabled(false)
                } else {
                  setIsEditDisabled(false);
                  setIsAcceptDeleteDisabled(false);
                  setSelectedBulk(` (${selection.length})`)
                  setSelectedEdit(data.find(x => x.id === selection[0]));
                  setSelectionModel(selection);
                }
              }}
              selectionModel={selectionModel}
              loading={loading}
              toolbar={true}
              columns={columnsDefinitions.availabilityColumns}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
