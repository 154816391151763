const dateColumn = (params) => {
  const value = params.value;

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  let formattedDate = new Date(value).toLocaleString('es-CL', options);
  formattedDate = formattedDate.replace(',', '')

  if (formattedDate === '01-01-1') {
    return "No Aplica";
  } else {
    return formattedDate;
  }
};

const hourColumn = (params) => {
  const value = params.value;

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'America/Santiago'
  };

  let formattedDate = new Date(value).toLocaleString('es-CL', options);
  formattedDate = formattedDate.replace(',', '')
  
  if (formattedDate === '00:00:00') {
    return "No Aplica";
  } else {
    return formattedDate;
  }
  ;
};

const hourFormatter = (params) => {
  return new Date(params.value).toLocaleTimeString('es-CL')
}


const indicator = (params) => {
  const dateTime1 = new Date(params.value)
  const dateTime2 = new Date(new Date())

  let diferenciaMins = (dateTime2 - dateTime1) / 60000

  if (diferenciaMins < 0) {
    diferenciaMins += 1440;
  }

  if (diferenciaMins >= 120) {
    return <div title="ALERTA: tiempo respuesta vencido – Enviar respuesta." style={{ background: "#FF624E", borderRadius: "50%", width: "14px", height: "14px", border: "thin solid black" }}></div>
  } else if (diferenciaMins > 105 && diferenciaMins < 120) {
    return <div title="ATENCION: Próximo a vencer." style={{ background: "#FFE55C", borderRadius: "50%", width: "14px", height: "14px", border: "thin solid black" }}></div>
  } else if (diferenciaMins >= 0 && diferenciaMins < 105) {
    return <div title="INFORMACIÓN: vigente, sin vencimiento próximo." style={{ background: "#36B37E", borderRadius: "50%", width: "14px", height: "14px", border: "thin solid black" }}></div>
  }
}

const usuario = (params) => {
  let user = params.value;

  if (user == null) {
    user = 'No Aplica';
  }
  return `${user}`;
}

export const columnsDefinitions = {
  availabilityColumns: [
    {
      headerName: "id",
      field: "id",
      type: "number",
      hide: true
    },
    {
      headerName: "⏱",
      field: "horaRegistro",
      type: "dateTime",
      hide: false,
      width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center',
      valueFormatter: hourFormatter,
      renderCell: indicator
    },
    {
      headerName: "#",
      field: "idRequerimiento",
      type: "number",
      hide: false,
      width: 120
    },
    {
      headerName: "Fecha Registro",
      field: "dateRegistro",
      type: "dateTime",
      hide: false,
      valueFormatter: dateColumn,
      width: 150
    },
    {
      headerName: "Hora Registro",
      field: "hourRegistro",
      type: "dateTime",
      hide: false,
      valueFormatter: hourColumn,
      width: 150
    },
    {
      headerName: "Estado",
      field: "estadonombre",
      type: "string",
      hide: false,
      width: 120
    },
    {
      headerName: "Existe Inst",
      field: "instalacionCercana",
      type: "string",
      hide: false,
      width: 100
    },
    {
      headerName: "Distancia Instalación",
      field: "distanciaInstalacion",
      type: "number",
      hide: false,
      width: 50
    },
    {
      headerName: "Existe GN",
      field: "existeGn",
      type: "number",
      hide: false,
      width: 90
    },
    {
      headerName: "Existe GLP",
      field: "existeGlp",
      type: "number",
      hide: false,
      width: 90
    },
    {
      headerName: "Latitud",
      field: "latitud",
      type: "number",
      hide: false,
      width: 130
    },
    {
      headerName: "Longitud",
      field: "longitud",
      type: "number",
      hide: false,
      width: 130
    },
    {
      headerName: "Empresa",
      field: "empresaMandante",
      type: "string",
      hide: false,
      width: 150
    },
    {
      headerName: "Rubro",
      field: "rubro",
      type: "string",
      hide: false
    },
    {
      headerName: "Calle",
      field: "calle",
      type: "string",
      hide: false,
      width: 300
    },
    {
      headerName: "Número",
      field: "numero",
      type: "string",
      hide: false,
      width: 100
    },
    {
      headerName: "Comuna",
      field: "nombreComuna",
      type: "string",
      hide: false,
      width: 150
    },
    {
      headerName: "Correo",
      field: "correo",
      type: "string",
      hide: false,
      width: 300
    },
    {
      headerName: "Teléfono",
      field: "telefono",
      type: "number",
      hide: false
    },
    {
      headerName: "Rut",
      field: "rut",
      type: "string",
      hide: false,
      width: 200
    },
    {
      headerName: "indicaaceptaterminos",
      field: "indicaaceptaterminos",
      type: "number",
      hide: false
    },
    {
      headerName: "Folio",
      field: "folio",
      type: "string",
      hide: false
    },
    {
      headerName: "Region",
      field: "idRegion",
      type: "number",
      hide: false
    },
    /*
    {
      headerName: "Usuario",
      field: "idUsuario",
      type: "number",
      hide: false,
      width: 80
    },
    */
    {
      headerName: "Fecha Recepción",
      field: "dateRecepcion",
      type: "dateTime",
      hide: false,
      valueFormatter: dateColumn,
      width: 150
    },
    {
      headerName: "Fecha Recepción",
      field: "hourRecepcion",
      type: "dateTime",
      hide: false,
      valueFormatter: hourColumn,
      width: 150
    },
    {
      headerName: "Usuario Recepción",
      field: "usuariorecepcion",
      type: "string",
      hide: false,
      width: 200,
      renderCell: usuario
    },
    {
      headerName: "Fecha Anulación",
      field: "dateAnulacion",
      type: "dateTime",
      hide: false,
      valueFormatter: dateColumn,
      width: 150
    },
    {
      headerName: "Hora Anulación",
      field: "hourAnulacion",
      type: "dateTime",
      hide: false,
      valueFormatter: hourColumn,
      width: 150
    },
    {
      headerName: "Usuario Anulación",
      field: "usuarioanulacion",
      type: "string",
      hide: false,
      width: 200,
      renderCell: usuario
    },
    {
      headerName: "Fecha Atención",
      field: "dateAtencion",
      type: "dateTime",
      hide: false,
      valueFormatter: dateColumn,
      width: 150
    },
    {
      headerName: "Hora Atención",
      field: "hourAtencion",
      type: "dateTime",
      hide: false,
      valueFormatter: hourColumn,
      width: 150
    },
    {
      headerName: "Usuario Atención",
      field: "usuarioatencion",
      type: "string",
      hide: false,
      width: 200,
      renderCell: usuario
    },
    {
      headerName: "Tipo de Trabajo",
      field: "tipoTrabajo",
      type: "string",
      hide: false,
      width: 300
    },
    {
      headerName: "Adj.GLP",
      field: "glp",
      type: "number",
      hide: false
    },
    {
      headerName: "Adj.GN",
      field: "gn",
      type: "number",
      hide: false
    },
    {
      headerName: "Certificado",
      field: "certificado",
      type: "string",
      hide: false
    }
  ],
  attachmentColumns: [
    {
      headerName: "id",
      field: "id",
      type: "number",
      hide: true,
      width: 10
    },
    {
      headerName: "Id",
      field: "idAdjunto",
      type: "number",
      hide: false
    },
    {
      headerName: "Nombre Archivo",
      field: "nombreArchivo",
      type: "string",
      hide: false,
      width: 400
    },
    {
      headerName: "Fecha Registro",
      field: "fechaRegistro",
      type: "string",
      hide: false,
      width: 330
    },
    {
      headerName: "Observación",
      field: "observacion",
      type: "string",
      hide: false,
      width: 440
    },
    {
      headerName: "Tipo Documento",
      field: "tipoRed",
      type: "string",
      hide: false,
      width: 100
    }/*,
    {
      headerName: "Archivo",
      field: "archivo",
      type: "string",
      hide: false,
      width: 330
    }*/
  ]
}
