import React from 'react'
import { Grid, Stack } from '@mui/material'
import Typography from "@mui/material/Typography"
import Divider from '@mui/material/Divider'

export const Message = ({ msg }) => {
  return (
    <Grid
      container
      rowSpacing={0}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{ border: '1px solid grey', borderRadius: 10, padding: 20, color: '#003087' }}>

      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        alignItems="center"
      >
        <img src='/images/Lipigas_color_horizontal.svg' style={{ width: '11%'}}></img>
        <Typography variant="h5" component="h2" mb={2}>
          Se ha perdido la conexión con el servidor
        </Typography>
      </Stack>

      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}></Grid>
      <Typography variant="h6" component="h2" mb={2} marginTop="5%" marginLeft="40%" marginRight="40%" color="Red">
        {msg}
      </Typography>

      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}></Grid>
      <Typography variant="subtitle1" component="h2" mb={3} marginLeft="20%" marginRight="20%">
      Intente nuevamente en unos minutos, si el problema persiste comuniquese con mesaayuda@lipigas.cl
      </Typography>
    </Grid>
  )
}
