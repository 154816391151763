const apiUrl = process.env.REACT_APP_API_COMMON_URL
const myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export const getStreets = async ({filter, comuna, cantidad}) => {
  //const url = `${apiUrl}/calles?filter=${filter}&comuna=${comuna}&cantidad=${cantidad}`;

  const url = `${apiUrl}/direcciones/calle`;

  var raw = {
		"filter": filter,
		"comuna": comuna,
		"cantidad": cantidad
	};

  try {
    /*const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders,
    });*/

    const resp = await fetch( url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json();

      return {
        data: data.data,
        status: 'OK'
      };
    } else {
      //const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: 'Error al recuperar las calles' //errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener las calles`
    };
  }

}