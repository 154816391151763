import React, { useEffect, useState } from "react"
import { styled } from '@mui/material/styles'
import { useHistory, useLocation } from "react-router-dom"
import Box from '@mui/material/Box'
import { Message } from "../common/Message"

const Input = styled('input')({
  display: 'none',
})

export const NotConnected = () => {
  const history = useHistory()
  const location = useLocation()
  const requirement = location.state

  sessionStorage.removeItem('usuarioConectado')
  sessionStorage.removeItem('isFirmante')
  sessionStorage.removeItem('token')

  useEffect(() => {
  }, []);

  return (
    <form>
      <Box sx={{ width: '100%', marginLeft: 1 }} >
        <Message msg={requirement.mensaje} />
      </Box>
    </form>
  )
}
