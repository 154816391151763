import React, { Fragment } from 'react'
import './App.css'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container, createTheme, ThemeProvider } from '@mui/material'
import { setLocale } from 'yup'
import { es } from 'yup-locales'
import { RequestScreen } from "./components/request/RequestScreen"
import { AvailabilityScreen } from "./components/availability/AvailabilityScreen"
import { FormTechnicalFeasibilityResult } from "./components/request/FormTechnicalFeasibilityResult"
import { SnackbarProvider } from "notistack"
import { AvailabilityEdit } from "./components/availability/AvailabilityEdit"
import { AvailabilityAttachment } from "./components/availability/AvailabilityAttachment"
import { NotConnected } from "./components/availability/NotConnected"
import { Height } from '@mui/icons-material'

function App() {
  setLocale(es);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1A53BA'
      },
      secondary: {
        main: '#1A53BA'
      },
      error: {
        main: '#FF624E',
        contrastText: '#FFFFFF'
      },
      success: {
        main: '#36B37E'
      },
    },
    components: {
      MuiFormControl: {
        defaultProps: {
          variant: 'outlined'
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: '1px solid #B3B9C4'
          },
        },
      }
    },
    typography: {
      fontFamily: [
        ' \'Poppins\', sans-serif'
      ]
    }
  });

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5}>
          <Container maxWidth="xl" sx={{paddingTop: 4}}>
            <Router>
              <Route exact path='/' component={RequestScreen}></Route>
              <Route exact path='/disponibilidad' component={AvailabilityScreen}></Route>
              <Route path='/resultado' component={FormTechnicalFeasibilityResult}></Route>
              <Route path='/editar' component={AvailabilityEdit}></Route>
              <Route path='/adjuntar' component={AvailabilityAttachment}></Route>
              <Route path='/no_conectado' component={NotConnected}></Route>
            </Router>
          </Container>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App;
