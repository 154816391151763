import React from 'react'
import DataGridNoRows from '../common/DataGridNoRows'
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {LinearProgress} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      //display: "none"
    }
  }
}));

export const TableGrid = ({ data, onSelectionModelChange = null, selectionModel, loading, toolbar, columns }) => {
  
  const classes = useStyles();
  return (
    <div 
      style={{ paddingRight: 5 }}>
        <DataGrid
          className={classes.root}
          autoHeight
          checkboxSelection={ true }
          //rowHeight={36}
          rows={data}
          columns={ columns }
          selectionModel={selectionModel}
          //hideFooter={true}
          onSelectionModelChange={onSelectionModelChange}
          components={{
            NoRowsOverlay: () => DataGridNoRows("no-data"),
            Toolbar: toolbar ? GridToolbar : toolbar,
            LoadingOverlay: LinearProgress
          }}
          loading={loading}
          density= 'compact'
          pageSize={10}
          localeText={{
            noRowsLabel: "No se ha encontrado datos.",
            noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            toolbarColumns: "Columnas",
            toolbarColumnsLabel: "Seleccionar columnas",
            toolbarFilters: "Filtros",
            toolbarFiltersLabel: "Ver filtros",
            toolbarFiltersTooltipHide: "Quitar filtros",
            toolbarFiltersTooltipShow: "Ver filtros",
          }}
          disableColumnSelector 
          disableDensitySelector
        />
    </div>
  )
}

