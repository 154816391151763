import React from 'react'
import { setLocale } from 'yup'
import { es } from 'yup-locales'
import { FormTechnicalFeasibility } from './FormTechnicalFeasibility'

setLocale(es);

export const RequestScreen = () => {
  return (
   <FormTechnicalFeasibility />
  )
}
