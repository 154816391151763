import React, { useEffect, useState } from "react"
import Swal from 'sweetalert2'
import { styled } from '@mui/material/styles'
import { columnsDefinitions } from './columnsDefinitions'
import { Button, FormControl, Grid, Stack, TextField } from '@mui/material'
import { attachmentSchema } from "../../utils/validations"
import { useForm, Controller } from "react-hook-form"
import { getAttachments, saveAttachment, removeAttachment, generateAttachment, updateObsAttachment, openAttachment } from "../../api/attachments/apiCalls"
import Autocomplete from '@mui/material/Autocomplete'
import { getTipoRed } from "../../api/comunes/apiCalls"
import { yupResolver } from "@hookform/resolvers/yup"
import { useHistory, useLocation } from "react-router-dom"
import Box from '@mui/material/Box'
import { HeaderTitle } from '../common/HeaderTitle'
import { TableGrid } from './TableGrid'
import { TitleFile } from '../common/TitleFile'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteForever from '@mui/icons-material/DeleteForever'
import SaveIcon from '@mui/icons-material/Save'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Spinner } from "../common/Spinner"
import { getAvailabilityById } from "../../api/availability/apiCalls"
import { isEmpty } from "lodash"
import { LibraryAdd, OpenInBrowser } from "@mui/icons-material"

const Input = styled('input')({
  display: 'none',
})

export const AvailabilityAttachment = () => {
  const history = useHistory()
  const location = useLocation()
  const requirement = location.state
  const [loading, setLoading] = useState(true)
  const [ready, setReady] = useState(true)
  const [data, setData] = useState([]);
  const [fileName, setFilename] = useState('')
  const [file, setFile] = useState({})
  const [selectedRequirements, setSelectedRequirements] = useState([])
  const [reload, setReload] = useState('');
  const [tiporedes, setTipoRedes] = useState([])
  const [tipored, setTipoRed] = useState(null)

  const usuarioConectado = sessionStorage.getItem('usuarioConectado')
  const isFirmante = sessionStorage.getItem('isFirmante')


  if (!requirement || !(requirement && requirement.defaultFormValues)) {
    return null
  }

  const [defaultFormValues, setDefaultFormValues] = useState({})
  const { register, handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(attachmentSchema) })

  useEffect(() => {
    const id = requirement.defaultFormValues.idRequerimiento

    getAvailabilityById(id).then((datosRequerimiento) => {
      const obj = datosRequerimiento.data.requerimientos.find(obj => obj.idRequerimiento === id)

      getAttachments(id).then(({ data }) => {
        setData(data.adjuntos)
        setLoading(false)
        setReady(true)
      })
      getTipoRed().then(({ data }) => {
        setTipoRedes(data)
      })
      setDefaultFormValues(obj)
    })
  }, [reload]);

  const handleChangeSelect = (name, obj) => {
    if (name === 'tipored') {
      setTipoRed(obj)
    }
  };


  const setFileAttached = file => {
    setFile(file.target.files[0])
    setFilename(file.target.files[0].name)
  }

  const generateFileAttached = () => {
    Swal.fire({
      title: '¡Generando Certificado!',
      html: 'En unos segundos estará listo ...',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false
    }).then
    generateAttachment(defaultFormValues.idRequerimiento, usuarioConectado)
      .then(({ data }) => {
        if (data.respuesta.cod_error === 9000) {
          Swal.fire({
            title: 'Certificado Existencia Redes',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            inputPlaceholder: 'Observación',
            html: '<iframe src="https://storage.googleapis.com/' + data.datosCertificado[0].certificado + '#toolbar=0" style="width:440px; height:596px;" frameborder="0"></iframe>',
            confirmButtonText: 'Guardar',
            showLoaderOnConfirm: true,
            preConfirm: (observacion) => {
              if (observacion == "") {
                Swal.showValidationMessage('Debe incluir una Observación')
              } else {
                return updateObsAttachment(data.datosCertificado[0].certificado, observacion)
                  .then(response => {
                    if (response.cod_error === 9000) {
                      return response.mensaje_usr
                    }
                  })
              }
            },
            allowOutsideClick: false
          }).then((result) => {
            if (result) {
              Swal.fire({
                title: 'Guardado',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                window.location.reload();
              }, 2000);
            }
          })
        }
        setReload(new Date())
        setFilename('')
        setReady(true)
      })
  }


  const onSubmit = datos => {
    setReady(false)
    const parseObject = {
      idRequerimiento: defaultFormValues.idRequerimiento,
      idUsuario: usuarioConectado,
      nombreArchivo: fileName,
      observacion: datos.observacion,
      archivo: file,
      idTipoRed: datos.tipored.id
    }

    saveAttachment(parseObject)
      .then(({ data }) => {
        if (data.cod_error === 9000) {
          Swal.fire({
            position: 'center',
            icon: data.cod_error === 9000 ? 'success' : 'error',
            title: data.cod_error === 9000 ? `Su archivo ${fileName} se adjuntó correctamente.` : data.mensaje_usr,
            showConfirmButton: false,
            timer: 2000
          })
        }
        setReload(new Date())
        setFilename('')
        setReady(true)
      })
  }

  const handelOpen = () => {
    openAttachment(selectedRequirements[0].idAdjunto)
      .then(({ data }) => {
        if (data.respuesta.cod_error === 9000) {
          Swal.fire({
            title: 'Documento adjunto',
            html: '<iframe src="' + data.adjuntos[0].mediaLink + '"#toolbar=0" style="width:440px; height:660px;" frameborder="0"></iframe>',
            confirmButtonText: 'Cerrar',
            confirmButtonColor: '#1A53BA',
            allowOutsideClick: true
          })
        }
      })
  }

  const handelDelete = () => {
    Swal.fire({
      title: 'Estas seguro que quieres eliminar el/los archivos?',
      text: "Esta acción no se podrá revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36B37E',
      cancelButtonColor: '#FF624E',
      confirmButtonText: 'Si, Eliminalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedRequirements.map(x => {
          const removeObj = {
            idAdjunto: x.idAdjunto,
            idUsuario: usuarioConectado //x.idUsuario
          }
          removeAttachment(removeObj)
            .then(({ data }) => {
              if (data.cod_error === 9000) {
                Swal.fire({
                  position: 'center',
                  icon: data.cod_error === 9000 ? 'success' : 'error',
                  title: data.cod_error === 9000 ? `Sus archivos se eliminaron correctamente.` : data.mensaje_usr,
                  showConfirmButton: false,
                  timer: 2000
                })
              }
            })
        })
        setReload(new Date())
      }
    })
  }

  const handleVolver = () => {
    history.push({
      pathname: `/disponibilidad`,
      state: { keepObject: requirement.defaultFormValues }
    })
  }

  return (
    <form>
      {defaultFormValues.idRequerimiento &&
        <Box sx={{ width: '100%', marginLeft: 1 }} >
          <HeaderTitle title='Visualizar y Adjuntar Archivos a Solicitud' />

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 4, sm: 2, md: 3 }}
            style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 2, marginTop: 40, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
          >
            <Grid item xs={12}
              style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 20, marginTop: 1, background: '#FFFFFF' }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <TextField
                    label={'Id Requerimiento'}
                    fullWidth
                    size="small"
                    defaultValue={defaultFormValues.idRequerimiento}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={'Empresa Solicitante'}
                    fullWidth
                    size="small"
                    defaultValue={defaultFormValues.empresaSolicitante}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={'Empresa Mandante'}
                    fullWidth
                    size="small"
                    defaultValue={defaultFormValues.empresaMandante}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={'Fecha Registro'}
                    fullWidth
                    size="small"
                    defaultValue={defaultFormValues.fechaRegistro}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}
              style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 10, marginTop: 2, background: '#FFFFFF' }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
              >

                <label htmlFor="contained-button-generatefile">
                  <Button
                    size="small"
                    variant="contained"
                    component="span"
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC" && isFirmante == 1) ? "" : "disabled"}
                    endIcon={<LibraryAdd />}
                    onClick={generateFileAttached}
                  >
                    Generar Certificado
                  </Button>
                </label>

                <label htmlFor="contained-button-file">
                  <Input type="file" accept="application/pdf, .docx" id="contained-button-file" onChange={setFileAttached}
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                  />

                  <Button
                    size="small"
                    variant="contained"
                    component="span"
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    endIcon={<AttachFileIcon />}
                  >
                    Adjuntar
                  </Button>
                </label>
                {fileName ? <TitleFile text={fileName} setFilename={setFilename} /> : null}
                <FormControl>
                  <Button
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={handleSubmit(onSubmit)}
                    endIcon={<SaveIcon />}
                    disabled={ready && fileName ? false : true}
                  >
                    Guardar
                  </Button>
                </FormControl>
                <FormControl>
                  <Button
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={handelOpen}
                    endIcon={<OpenInBrowser />}
                    disabled={selectedRequirements.length != 1}
                  >
                    Ver
                  </Button>
                </FormControl>
                <FormControl>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={handelDelete}
                    endIcon={<DeleteForever />}
                    disabled={!selectedRequirements.length > 0 || defaultFormValues.estado == "ATE" || defaultFormValues.estado == "ANU"}
                  >
                    Eliminar
                  </Button>
                </FormControl>
                <FormControl>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={handleVolver}
                    endIcon={<KeyboardBackspaceIcon />}
                  >
                    Volver
                  </Button>
                </FormControl>
              </Stack>
            </Grid>

            {fileName &&
              <Grid item xs={8}
                style={{ borderTop: '1px solid transparent', borderRight: '1px solid transparent', borderBottom: '1px solid #B3B9C4', borderLeft: '1px solid #B3B9C4', padding: 10, marginTop: -12, background: '#FFFFFF', borderRadius: 5 }}
              >
                <TextField
                  {...register('observacion')}
                  error={!!errors.observacion}
                  label="Observación"
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                />
              </Grid>
            }
            {fileName &&
              <Grid item xs={4}
                style={{ borderTop: '1px solid transparent', borderRight: '1px solid #B3B9C4', borderBottom: '1px solid #B3B9C4', borderLeft: '1px solid transparent', padding: 10, marginTop: -12, background: '#FFFFFF', borderRadius: 5 }}
              >
                <Controller
                  control={control}
                  name="tipored"
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      value={tipored}
                      options={tiporedes}
                      getOptionLabel={(option) => option.id ? `${option.id} - ${option.nombre}` : ''}
                      onChange={(event, value) => {
                        if (!isEmpty(value)) {
                          handleChangeSelect('tipored', value)
                        }
                        onChange(value)
                      }}
                      onInputChange={(_, value) => onChange(tipored)}
                      renderInput={params => <TextField
                        {...params}
                        error={!!errors.tipored}
                        inputProps={{ ...params.inputProps }}
                        helperText={errors.tipored?.message} label="Tipo Documento" // Modificar valor en DB y variables
                      />
                      }
                    />
                  )}
                />
              </Grid>
            }
            {!ready &&
              <Grid item xs={12}
                style={{ borderTop: '1px solid transparent', borderRight: '1px solid #B3B9C4', borderBottom: '1px solid #B3B9C4', borderLeft: '1px solid #B3B9C4', padding: 10, marginTop: -12, background: '#FFFFFF', borderRadius: 5 }}
              >
                <Spinner />
              </Grid>
            }

            <Grid item xs={12}
              style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 10, marginTop: 2, background: '#FFFFFF' }}
            >
              <Grid item xs={12}>
                <TableGrid
                  data={data.filter(x => x.eliminado === 0)}
                  onSelectionModelChange={(selectionModel) => {
                    setSelectedRequirements(selectionModel.map(x => data.find(y => y.id === x)))
                  }}
                  loading={loading}
                  toolbar={false}
                  columns={columnsDefinitions.attachmentColumns}
                />
              </Grid>
            </Grid>

          </Grid>
        </Box>
      }
    </form>
  )
}
