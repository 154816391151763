import React, { useEffect, useState } from "react"
import Swal from 'sweetalert2'
import { Button, FormControl, Grid, InputLabel, Stack, TextField } from '@mui/material'
import Autocomplete from "@mui/material/Autocomplete"
import { isEmpty } from "lodash"
import { editSchema } from "../../utils/validations"
import { Controller, useForm } from "react-hook-form"
import { getComunas, getRegions } from "../../api/comunes/apiCalls"
import { getStreets } from "../../api/streets/apiCalls"
import { getAvailabilityById } from "../../api/availability/apiCalls"
import { update } from "../../api/availability/apiCalls"
import Typography from "@mui/material/Typography"
import { yupResolver } from "@hookform/resolvers/yup"
import { useHistory, useLocation } from "react-router-dom"
import Box from '@mui/material/Box'
import { HeaderTitle } from '../common/HeaderTitle'


export const AvailabilityEdit = () => {
  const history = useHistory()
  const location = useLocation()
  const data = location.state

  if (!data || !(data && data.defaultFormValues)) {
    return null
  }

  const [communes, setCommunes] = useState([])
  const [reload, setReload] = useState('')
  const [commune, setCommune] = useState(null)
  const [streets, setStreets] = useState([])
  const [street, setStreet] = useState(null)
  const [regions, setRegions] = useState([])
  const [region, setRegion] = useState(null)
  const [defaultFormValues, setDefaultFormValues] = useState({})
  const { register, handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(editSchema) })

  useEffect(() => {
    const id = data.defaultFormValues.idRequerimiento

    getAvailabilityById(id).then(({ data }) => {
      const obj = data.requerimientos.find(obj => obj.idRequerimiento === id)
      getRegions().then(({ data }) => {
        setRegions(data)
        const defaultRegion = data.find(region => region.id === obj.idRegion)
        setRegion(defaultRegion)


        getComunas(defaultRegion.id).then(({ data }) => {
          setCommunes(data)
          setCommune(data.find(commune => commune.id === obj.idComuna))
        })

        // TODO: (Workaround) Debería hacer match por id de calle y no por nombre, se está guardando el string del nombre y no su id
        getStreets({
          filter: obj.calle,
          comuna: obj.idComuna,
          cantidad: 1
        }).then((response) => {
          if (response.data.length > 0) {
            setStreets(response.data)
            setStreet(response.data[0])
          }
        })

        setDefaultFormValues(obj)
      })

    })
  }, []);

  const handleChangeSelect = (name, obj) => {
    if (name === 'region') {
      setCommune(null)
      setStreet(null)
      setRegion(obj)
      getComunas(obj.id).then(({ data }) => {
        setCommunes(data)
      })
    }
    if (name === 'comuna') {
      setStreet(null)
      setCommune(obj)
    }
    if (name === 'calle') {
      setStreet(obj)
    }
  };

  const handleCancel = () => history.push({ pathname: '/disponibilidad', state: { keepObject: defaultFormValues } });
  const usuarioConectado = sessionStorage.getItem('usuarioConectado');

  const onSubmit = (datos) => {
    const parseObject = {
      id: defaultFormValues.id,
      empresaMandante: datos.empresaMandante,
      rubro: datos.rubro,
      empresaSolicitante: datos.empresaSolicitante,
      contacto: datos.contacto,
      correo: datos.correo,
      telefono: datos.telefono,
      idRegion: datos.region.id,
      idComuna: datos.comuna.id,
      calle: datos.calle.calle,
      numero: datos.numero,
      rut: datos.rut,
      idUsuario: usuarioConectado,
      tipoTrabajo: datos.tipoTrabajo
    }
    update({ parseObject })
      .then(({ data }) => {
        Swal.fire({
          position: 'center',
          icon: data.cod_error === 9000 ? 'success' : 'error',
          title: data.cod_error === 9000 ? `Su solictud se actualizó correctamente.` : data.mensaje_usr,
          showConfirmButton: true,
          confirmButtonColor: '#36B37E',
          confirmButtonText: 'Aceptar'
        })
        setReload(new Date())
      })
  }

  return (
    <form>
      {defaultFormValues.idRequerimiento &&
        <Box sx={{ width: '100%', marginLeft: 1 }} >
          <HeaderTitle title='Editar Solicitud' />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 4, sm: 2, md: 3 }}
            style={{ borderBottom: '1px solid transparent', borderLeft: '1px solid #B3B9C4', borderRight: '1px solid #B3B9C4', borderTop: '1px solid #B3B9C4', borderRadius: 10, paddingTop: 2, paddingLeft: 2, paddingRight: 2, paddingBottom: 1, marginTop: 40, background: 'linear-gradient(90deg, #D5CD06 -0.03%, #C2C40E 11.98%, #90B025 36.98%, #428E4A 71.98%, #007369 99.98%)' }}
          >
            <Grid item xs={12}
              style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 20, marginTop: 1, background: '#FFFFFF' }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{ borderRadius: 10, marginTop: 10 }}
              >
                <Grid item xs={6}>
                  <TextField
                    {...register('empresaMandante')}
                    error={!!errors.empresaMandante}
                    helperText={errors.empresaMandante?.message}
                    label={'Empresa Mandante'}
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.empresaMandante}
                  />

                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('rubro')}
                    error={!!errors.rubro}
                    helperText={errors.rubro?.message}
                    label={'Rubro Empresa Mandante'}
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.rubro}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('empresaSolicitante')}
                    error={!!errors.empresaSolicitante}
                    helperText={errors.empresaSolicitante?.message}
                    label={'Empresa Solicitante'}
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.empresaSolicitante}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('contacto')}
                    error={!!errors.contacto}
                    helperText={errors.contacto?.message}
                    label={'Nombre del contacto'}
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.contacto}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('correo')}
                    error={!!errors.correo}
                    helperText={errors.correo?.message}
                    label={'E-Mail'}
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.correo}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('telefono')}
                    error={!!errors.telefono}
                    placeholder={'Número de largo 9'}
                    helperText={errors.telefono?.message}
                    label="Teléfono"
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.telefono}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="region"
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        value={region}
                        options={regions}
                        getOptionLabel={(option) => option.id ? `${option.id} - ${option.nombre}` : ''}
                        onChange={(event, value) => {
                          if (!isEmpty(value)) {
                            handleChangeSelect("region", value)
                          }
                          onChange(value)
                        }}
                        onInputChange={() => onChange(region)}
                        renderInput={params => <TextField
                          {...params}
                          error={!!errors.region}
                          inputProps={{ ...params.inputProps }}
                          helperText={errors.region?.message} label="Seleccione Región"
                        />
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="comuna"
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        value={commune}
                        options={communes}
                        getOptionLabel={(option) => `${option.id} - ${option.nombre}`}
                        onChange={(event, value) => {
                          if (!isEmpty(value)) {
                            handleChangeSelect('comuna', value)
                          }
                          onChange(value)
                        }}
                        onInputChange={(_, value) => onChange(commune)}
                        renderInput={(params) => <TextField
                          {...params}
                          error={!!errors.comuna}
                          inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                          helperText={errors.comuna?.message} label="Seleccione Comuna"
                        />}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Controller
                    control={control}
                    name="calle"
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        value={street}
                        options={streets}
                        filterOptions={(x) => x}
                        onInputChange={(_, value) => {
                          if (commune && commune.id && value) {
                            getStreets({
                              filter: value,
                              comuna: commune.id,
                              cantidad: 20
                            }).then((response) => {
                              setStreets(response.data)
                            })

                            if (!isEmpty(value)) {
                              const newStreetObj = {
                                callId: 0,
                                calle: value,
                                calleBuscada: null
                              };
                              handleChangeSelect("calle", newStreetObj)
                              onChange(newStreetObj)
                            }
                          }
                        }}
                        getOptionLabel={(option) => `${option.calle}`}
                        disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                        renderInput={(params) => <TextField
                          {...params}
                          error={!!errors.calle}
                          helperText={errors.calle?.message} label="Seleccione Calle"
                        />}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
                    {...register('numero')}
                    error={!!errors.numero}
                    helperText={errors.numero?.message}
                    label="Número de dirección"
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.numero}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="rut">RUT</InputLabel>
                  <TextField
                    {...register('rut')}
                    error={!!errors.rut}
                    helperText={errors.rut?.message}
                    fullWidth
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.rut}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('tipoTrabajo')}
                    error={!!errors.tipoTrabajo}
                    label="Tipo de Trabajo"
                    multiline
                    fullWidth
                    maxRows={2}
                    inputProps={{ maxLength: 250 }}
                    disabled={(defaultFormValues.estado == "ING" || defaultFormValues.estado == "REC") ? "" : "disabled"}
                    defaultValue={defaultFormValues.tipoTrabajo}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}
              style={{ border: '1px solid #B3B9C4', borderRadius: 10, padding: 20, marginTop: 2, background: '#FFFFFF' }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{ borderRadius: 10 }}
              >
                <Grid item xs={12}>
                  <Stack direction="row" spacing={6} justifyContent="center">
                    <FormControl>
                      <Button variant="contained" color="secondary" onClick={handleCancel}>Volver</Button>
                    </FormControl>
                    <FormControl>
                      <Button
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        color="success"
                      // style={{ backgroundColor: '#003087', color: 'white'}}
                      >
                        Guardar
                      </Button>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    </form>
  )
}
