import {useHistory, useParams} from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

export const FormTechnicalFeasibilityResult = () => {
  const history = useHistory();
  const result = history.location.state

  return (

    <Card sx={{minWidth: 300, marginTop: 10}}>

      <CardContent>
        <Typography md={{fontSize: 40}} color="text.secondary" gutterBottom>

          Estimado: {result.empresaMandante}

        </Typography>

        <br/>

        <Typography sx={{fontSize: 20}} variant="body2">

          Su número de ticket es: {result.numeroTicket}

          <br/>

        </Typography>

        <br/>

        <Typography>

          Revisaremos y le confirmaremos un plazo no superior a x minutos.

          Comunícate a 8000-600 opción 3.

        </Typography>

      </CardContent>

      <CardActions>

        <Button variant="contained" onClick={() => history.push('/')}>Confirmar</Button>

      </CardActions>

    </Card>

  );

}

