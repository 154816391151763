const apiUrl = process.env.REACT_APP_API_URL
const myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');


export const getComunas = async id => {
  const url = `${apiUrl}/Comunes/Comunas?idRegion=${id}`;

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders,
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json()

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }

}

export const getRegions = async () => {
  const url = `${apiUrl}/Comunes/Regiones`;

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders,
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json()

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }  
}

export const getTipoRed = async () => {
  const url = `${apiUrl}/Comunes/TipoRed`;

  try {
    const resp = await fetch(url, {
      method: 'get',
      headers: myHeaders,
    });

    if (resp.status === 200 || resp.status === 201) {
      const data = await resp.json()

      return {
        data,
        status: 'OK'
      };
    } else {
      const {errors} = await resp.json();
      return {
        status: 'NOK',
        message: errors[0].msg
      };
    }
  } catch (err) {
    return {
      status: 'NOK',
      message: `No se pudo conectar para obtener los estados`
    };
  }  
}
